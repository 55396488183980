.magic-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 600px) {
  .magic-center > span center {
    font-size: 23px;
  }
}
.error-background {
  background: repeating-linear-gradient(-45deg, #ffcdd2, #ffcdd2 10px, #ffdede 10px, #ffdede 20px);
}
.flip {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
html,
body {
  line-height: 1.7em;
  margin: 0;
  padding: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: relative;
}
h5 {
  font-size: 14px;
  font-weight: normal;
}
#applicationHost {
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
@media (max-width: 700px) {
  .ToggleNorthPanel {
    top: 120px;
  }
}
.touch .view.panel.metrics > section .nav-tabs,
.touch .view.panel.locations > section .nav-tabs {
  position: absolute;
  left: 0;
  right: 0;
  top: 8px;
  padding-left: 12px;
  height: 40px;
}
.touch .view.panel.metrics > section .nav-tabs > li,
.touch .view.panel.locations > section .nav-tabs > li {
  height: 40px;
}
.touch .view.panel.metrics > section .nav-tabs > li > a,
.touch .view.panel.locations > section .nav-tabs > li > a {
  height: 40px;
  font-size: 18px;
}
.touch .view.panel.metrics > section > .tab-content,
.touch .view.panel.locations > section > .tab-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 56px;
}
.tooltip.tooltip--top,
.tooltip.tooltip--bottom,
.tooltip.tooltip--left {
  position: relative;
}
.tooltip.tooltip--top:after,
.tooltip.tooltip--bottom:after,
.tooltip.tooltip--left:after {
  content: "";
  border-style: solid;
  border-width: 9px;
  width: 0;
  height: 0;
  position: absolute;
}
.tooltip.tooltip--top:before,
.tooltip.tooltip--bottom:before,
.tooltip.tooltip--left:before {
  content: "";
  border-style: solid;
  border-width: 10px;
  width: 0;
  height: 0;
  position: absolute;
}
.tooltip.tooltip--top:after {
  left: 50%;
  bottom: -18px;
  margin-left: -10px;
  border-color: #ffffff transparent transparent transparent;
}
.tooltip.tooltip--top:before {
  bottom: -20px;
  left: 50%;
  margin-left: -11px;
  border-color: #cccccc transparent transparent transparent;
}
.tooltip.tooltip--bottom:after {
  left: 50%;
  top: -18px;
  margin-left: -10px;
  border-color: transparent transparent #ffffff transparent;
}
.tooltip.tooltip--bottom:before {
  left: 50%;
  top: -20px;
  margin-left: -11px;
  border-color: transparent transparent #cccccc transparent;
}
.tooltip.tooltip--left:after {
  top: 50%;
  right: -18px;
  margin-top: -10px;
  border-color: transparent transparent transparent #ffffff;
}
.tooltip.tooltip--left:before {
  top: 50%;
  right: -20px;
  margin-top: -11px;
  border-color: transparent transparent transparent #cccccc;
}
.tooltip.tooltip--global {
  width: 200px;
  text-rendering: geometricPrecision;
  border-style: solid;
  border-width: thin;
  border-color: #cccccc;
  border-radius: 2px;
  position: relative;
}
main#content {
  margin: 0 auto;
  display: block;
  height: 100%;
}
.icon > svg {
  fill: #ffffff;
}
.icon.active > svg {
  fill: #41c7ff;
}
.btn-context-menu > svg {
  fill: #808080;
}
.btn-context-menu.active > svg {
  fill: #64b5f6;
}
.btn-context-menu.active > svg {
  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
}
a.light {
  color: #43a2e4;
}
.luxms-header-tree {
  min-height: 25px;
}
.VizelHeatMap {
  width: auto;
  height: auto;
}
.VizelLCard {
  overflow-y: auto !important;
}
.VizelMap .VizelLCard {
  max-height: 60vh;
}
.VizelListItem {
  position: relative;
  min-height: 100%;
}
.VizelPlot {
  overflow: hidden;
  position: relative;
}
.VizelPlot .highcharts-container {
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.Vizel.plot.classified-bar .highcharts-container,
.Vizel.plot.compare-sort .highcharts-container {
  left: 0 !important;
  top: 0 !important;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
#layout-content {
  height: 100%;
}
.roots.map {
  height: 100%;
}
.roots.map .map-container {
  height: 100%;
}
.ui-layout-toggler,
.ui-layout-resizer {
  overflow: visible !important;
}
.provider > .inner,
.contacts > .inner {
  padding-left: 10px;
}
/**
*	Панели локации и параметров
*/
.touch .panel.metrics .tab-pane > .wrapper > .panel > .panel-heading {
  min-height: 44px !important;
  font-size: 22px  !important;
  padding-top: 10px  !important;
}
.tab-content {
  padding: 0;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.list-group {
  margin-bottom: 5px;
}
.list-group .list-group {
  margin-left: 0px;
}
a:focus {
  outline: none;
}
.leaflet-div-icon {
  border: 0px !important;
  background-color: transparent !important;
}
/*Show markers */
.marker-container.markers > .bar {
  display: none !important;
}
.marker-container.markers > .pie {
  display: none !important;
}
.marker-container.markers > .marker {
  display: inline-block !important;
}
.marker-container.markers > .marker img {
  margin-left: 22px;
  margin-top: 25px;
}
/*Hide all */
.marker-container.hidden > .bar {
  display: none !important;
}
.marker-container.hidden > .pie {
  display: none !important;
}
.marker-container.hidden > .marker {
  display: none !important;
}
.marker-cluster-custom div {
  background-color: inherit;
}
.luxmsicon {
  display: block;
  width: 28px;
  height: 24px;
}
.luxmsicon-big {
  width: 32px;
  height: 32px;
}
.luxms-icon-text {
  padding-top: 2px;
  height: 24px;
  width: 120px;
  font-weight: bold;
  display: block;
}
.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
.y.axis {
  font-size: 1em;
}
.chart-title {
  background-color: #6F6F6F;
  color: #eee;
  border-radius: 2px;
  border: 1px solid #4F4F4E;
  text-align: center;
}
/* Фикс окна детализации */
#chart-detail-wnd {
  position: absolute;
  padding-top: 65px;
}
#chart-detail-wnd > .modal-dialog {
  padding-top: 0;
  position: absolute;
  overflow: hidden;
  width: 930px;
  height: 558px;
  left: 50%;
  top: 0;
  max-width: 100%;
  max-height: 100%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
#chart-detail-wnd > .modal-dialog > .modal-content {
  position: relative;
  width: 100%;
  height: 100%;
}
#chart-detail-wnd > .modal-dialog > .modal-content > .modal-body {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  top: 40px;
  padding: 0;
}
#chart-detail-wnd > .modal-dialog > .modal-content > .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
}
#chart-detail-wnd g rect:hover {
  fill-opacity: 0.6;
}
.chart-buttons button {
  margin: 5px;
}
.color-map-active {
  color: whitesmoke;
  font-size: 21px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}
.tree .option-name,
.tree .location-name {
  padding: 10px;
}
.tree .list-group-item {
  padding: 0 15px;
}
.option-color-col {
  padding: 10px 15px;
}
.option-color,
.location-color {
  cursor: pointer;
}
.location-color-col {
  padding: 15px;
}
.bar > div:first-child {
  width: 70px;
}
.bar svg:empty {
  /*visibility: hidden;*/
  display: none;
}
.bar svg:not(:empty) {
  /*visibility: visible;*/
  display: block;
}
.chart-title {
  position: relative;
  left: -50%;
  min-width: 70px;
  padding: 0 3px;
}
.tag {
  cursor: pointer;
  margin: 24px 24px 24px 0;
  display: inline-block;
  white-space: nowrap;
  color: #073d6d;
}
.tag > span {
  font-size: 1.1em;
}
.panel-title a {
  cursor: pointer;
  color: black;
}
.red {
  color: red;
}
.param-list {
  overflow: auto;
  max-height: 200px;
}
.param-list li {
  cursor: pointer;
}
.param-list li:hover {
  background-color: #E4EFF8;
}
.notIn,
.notIn.option {
  cursor: pointer;
  background-color: #073d6d;
  color: white;
}
.param-list li.selected {
  background-color: #aaceee;
}
.param-list li.selected:hover {
  background-color: #82A2BD;
}
.h-tab {
  margin-right: 10px;
}
.page-active {
  color: red !important;
}
.vizel.table1d table {
  width: 100%;
  max-width: 100%;
  border: 1px solid #f0f0f0;
  border-collapse: collapse;
  border-spacing: 0;
}
.VizelMap {
  position: relative;
}
.VizelMap .VizelMap__MapContainer {
  width: 100%;
  height: 100%;
}
/* legend on map */
.legend {
  position: relative;
  color: black;
  padding-bottom: 10px;
}
.legend table {
  width: 100%;
}
.legend td {
  border-collapse: collapse;
  height: 32px;
  vertical-align: middle;
  text-align: left;
}
.legend thead td {
  text-align: center;
  /*height: 40px;*/
}
.legend thead {
  border-bottom: 1px solid white;
}
.legend .colored-item {
  width: 32px;
  text-align: center;
}
.legend .colored-item span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
}
.panels.legend.map-metrics {
  overflow: hidden;
  font-size: 15px;
  padding: 0;
}
.panels.legend.map-metrics ul {
  list-style: none;
  margin: 0;
  padding: 20px 10px;
}
.panels.legend.map-metrics ul > li:first-child {
  padding-right: 30px;
}
.panels.legend.map-metrics li {
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin: 6px 0;
}
.panels.legend.map-metrics span {
  vertical-align: baseline;
  font-weight: normal;
  line-height: 17px;
}
.panels.legend.map-metrics span.text {
  margin-left: 10px;
  text-rendering: geometricPrecision;
}
.panels.legend.map-metrics .color-mark {
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin: 3px 6px 3px 3px;
  flex-grow: 0;
  flex-shrink: 0;
}
.panels.legend.map-metrics .no-data {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #cccccc;
  padding: 20px 10px;
}
.ui-layout-pane {
  padding: 0;
  background: white;
  border: 1px solid #bbbbbb;
  overflow: auto;
}
.ui-layout-resizer-open {
  border: none;
  background: #accdd4;
}
.ui-layout-resizer-open:hover {
  background: #DDD;
  background-color: #3ea1b5;
  background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(0, #accdd4), color-stop(0.559, #189ab1), color-stop(1, #087b95), color-stop(1, #70c0d1));
  background-image: -webkit-repeating-linear-gradient(left, #accdd4 0%, #189ab1 55.9%, #087b95 100%, #70c0d1 100%);
  background-image: repeating-linear-gradient(to right, #accdd4 0%, #189ab1 55.9%, #087b95 100%, #70c0d1 100%);
  background-image: -ms-repeating-linear-gradient(left, #accdd4 0%, #189ab1 55.9%, #087b95 100%, #70c0d1 100%);
}
.dropdown-menu > li > a:hover {
  color: black;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
ul.drilldown {
  list-style: none;
}
ul.drilldown > li {
  margin: 0;
  padding: 5px;
  display: block;
}
ul.drilldown > li:hover {
  background: #ccc;
  cursor: pointer;
}
/* padding at every jquery-layout panel */
.ui-layout-pane {
  padding: 0 !important;
}
.panel-default > .panel-heading {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: none;
  font-size: 1.1em;
}
.panel-default > .panel-heading a {
  color: #87C8FF;
}
.panel-default > .panel-heading > .panel-heading:hover {
  background-color: #000000;
}
.panel-default > .panel-heading > .panel-heading.selected {
  background-color: rgba(0, 0, 0, 0);
}
.panel-collapse .xtree {
  padding: 4px;
}
.touch .leaflet-container a.leaflet-popup-close-button {
  width: 28px;
  height: 24px;
  font-size: 26px;
}
.touch .xtree li {
  padding: 6px 0;
  height: 31px;
}
.touch .xtree .toggle-icon {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 34px;
}
.touch .xtree .node-label {
  height: 34px;
  padding: 0 43px 0 40px;
  line-height: 34px;
  font-size: 18px;
}
.touch .xtree .checkbox {
  width: 32px;
  height: 32px;
  font-size: 30px;
  padding-left: 2px;
  padding-top: 6px;
}
.touch .xtree .delete-parameter {
  font-size: 40px;
  right: 50px;
  top: 6px;
}
.xtree {
  color: #ffffff;
  fill: white;
}
.xtree ul {
  margin: 0;
  padding: 0;
}
.xtree ul ul {
  padding-left: 12px;
}
.xtree li {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 28px;
  line-height: 29px;
  font-size: 13.5px;
  position: relative;
}
.xtree li > div {
  position: relative;
  cursor: pointer !important;
  height: 100%;
  width: 100%;
}
.xtree .toggle-icon {
  position: absolute;
  top: 0;
  left: 0px;
  padding-left: 6px;
  width: 29px;
  height: 29px;
  font-size: 18px;
  font-family: Arial, Verdana, sans-serif;
  overflow: hidden;
}
.xtree .checkbox {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-family: Arial, Verdana, sans-serif;
  overflow: hidden;
  color: #111111;
  border: 1px solid #999999;
  border-radius: 0px;
  box-sizing: border-box;
}
.xtree .checkbox > svg {
  position: absolute;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 2px;
}
.xtree .node-label {
  position: relative;
  padding: 2px 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /*word-break:break-all;*/
  font-size: 100%;
  font-weight: normal;
  text-align: left;
}
.xtree .delete-parameter {
  position: absolute;
  right: 30px;
  top: 1px;
  cursor: pointer;
  font-size: 1.8em;
  color: #e0e0e0;
}
.xtree li:hover {
  background: rgba(0, 0, 0, 0.3);
}
.xtree li:hover .node-label {
  color: #ffffff;
}
.xtree li:hover .checkbox {
  border-color: #ffffff;
}
.xtree.not-interactive .node-label {
  padding: 2px 30px 2px 9px;
}
.xtree.not-interactive li > div {
  cursor: default !important;
}
.xtree.not-interactive li:hover {
  background: none;
}
.xtree.not-interactive li:hover .node-label {
  color: #ffffff;
}
.xtree.not-interactive li:hover .checkbox {
  border-color: #ffffff;
}
.map-camera-marker {
  width: 24px;
  height: 24px;
  border: 1px solid #999999;
  border-radius: 12px;
  background-color: #ffffff;
  opacity: 0.8;
}
.hidden-marker {
  display: none !important;
}
.leaflet-popup-close-button {
  z-index: 1;
}
.leaflet-popup-content {
  margin: 0;
}
.leaflet-popup-content-wrapper {
  border-radius: 2px !important;
  color: #333;
}
.leaflet-popup-content-wrapper .leaflet-popup-content {
  margin: 10px !important;
}
.leaflet-popup-content-wrapper h2 {
  font-size: 18px;
  line-height: 18px;
  margin: 0 10px;
  text-align: center;
}
.leaflet-popup-content-wrapper hr {
  margin: 3px ;
}
.leaflet-popup-content-wrapper .name {
  text-align: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  padding-right: 5px;
  vertical-align: middle;
  padding-bottom: 3px;
}
.leaflet-popup-content-wrapper .value {
  font-size: 11px;
  line-height: 12px;
  text-align: left;
  vertical-align: bottom;
  padding-bottom: 3px;
}
.slideshow.pane {
  height: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: height 0.3s linear;
  -moz-transition: height 0.3s linear;
  -o-transition: height 0.3s linear;
  transition: height 0.3s linear;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  color: white;
}
.slideshow.pane > section {
  height: 40px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.slideshow.pane > section h2 {
  font-size: 18px;
  font-weight: normal;
  line-height: 40px;
  margin: 0 2em;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.slideshow.pane > section > aside.control {
  display: flex;
}
.slideshow.pane > section > aside.control span {
  margin: 0 1em;
  white-space: nowrap;
}
.slideshow.pane > section > aside.control > .bi-icon {
  width: 40px;
  height: 40px;
}
.slideshow.pane > section > aside.control > .bi-icon svg {
  width: 62%;
  height: 62%;
}
.slideshow.pane > section > aside.control > .bi-icon.bookmarks-description svg {
  width: 75%;
  height: 75%;
}
.slideshow.pane > section > aside.control .description {
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  width: 200px;
  margin-left: -80px;
  bottom: 5em;
  padding: 1em;
  font-weight: 100;
  white-space: pre-line;
}
main.f_slideshow-pane-active .pane.slideshow {
  height: 40px;
}
main.f_slideshow-pane-active .ds-shell {
  bottom: 40px;
}
html.ug-theme-light .ug.btn {
  border-radius: 0 !important;
  border: 2px solid black;
  background-color: transparent;
  color: black;
  transform-style: preserve-3d;
  perspective: 1000px;
  transform-origin: 50% 50%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
html.ug-theme-light .ug.btn > span {
  pointer-events: none;
}
html.ug-theme-light .ug.btn.disabled {
  border-color: #333333;
  color: #333333;
}
.shell-layer-shadow {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
.shell-layer-wrapper {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.shell-layer-wrapper > section {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #cccccc;
  background: white;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.shell-layer-wrapper > section > header {
  background-color: #e3f2fd;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  padding: 0 16px;
  overflow: hidden;
  min-width: 100%;
  top: 0;
}
.assign-dialog {
  width: 400px;
  max-width: 90%;
}
.assign-dialog > header {
  display: flex;
  padding: 0 0 0 16px !important;
}
.assign-dialog > header > h2 {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.assign-dialog > header > span {
  width: 40px;
  height: 40px;
}
.assign-dialog .assign-dialog-body {
  padding: 20px 16px;
}
.assign-dialog .assign-dialog-body > div {
  margin-top: 5px;
  margin-bottom: 5px;
}
.assign-dialog .assign-dialog-body > div textarea {
  width: 100%;
  height: 100px;
}
.assign-dialog input,
.assign-dialog textarea {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #DDDDDD;
}
.assign-dialog input:focus,
.assign-dialog textarea:focus {
  box-shadow: 0 0 5px #51cbee;
  padding: 3px 0px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #51cbee;
}
.map-metrics.legend {
  position: absolute;
  left: 50px;
  top: 50px;
  z-index: 2;
  background: black;
  width: 250px;
  color: white;
  opacity: 0.8;
  padding-bottom: 10px;
  border-radius: 2px;
}
